<template lang="pug">
#app
  v-container(grid-list-xl, fluid)
    v-layout(row, wrap)
      v-flex(lg12)
        v-card
          v-toolbar(card, color='white')
            v-text-field.hidden-sm-and-down(flat, solo, prepend-icon='search', placeholder='Buscar feedback', v-model='search', hide-details)
            v-switch.mt-4(:false-value="0", :true-value="1", :color="filter.ativo ? 'success': ''", :label="filter.ativo ? 'Feedbacks ativos' : 'Feedbacks inativos'", v-model="filter.ativo")
            v-btn(color="bt-primary", @click="openDialogFeedback()").white--text Cadastrar
          v-divider
          v-card-text.pa-0
            v-data-table.elevation-1(:headers='headers', :search='search', :items='list', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]", :loading="loading")
              template(slot='items', slot-scope='props')
                td(width="5%").text-xs-center {{ props.item.id }}
                td.text-xs-left {{ props.item.texto }}
                td.text-xs-left {{ props.item.categoria_feedback.texto }}
                td(width="15%").text-xs-center
                  v-tooltip(top)
                    v-btn(slot="activator", flat, icon, fab, dark, color='bt-primary', small, @click="openDialogFeedback(props.item)")
                      v-icon edit
                    span Editar
                  v-tooltip(top)
                    v-btn(slot="activator", flat, icon, fab, dark, :color="props.item.ativo ? 'red' : 'warning'", small, @click="changeAtivo(props.item)")
                      v-icon {{ props.item.ativo ? 'delete' : 'restore' }}
                    span {{ props.item.ativo ? 'Desativar' : 'Ativar' }}
                    
    v-dialog(v-model="dialogFeedback", width="auto", scrollable, max-width="800px", persistent, v-if="dialogFeedback")
      v-card
        form(@submit.prevent="salvar")
          v-card-title
            span.headline {{ feedbackAux.id ? 'Editar Feedback' : 'Cadastrar Feedback' }}
            v-spacer
            v-tooltip(top)
              v-btn(icon, slot="activator", @click.native="$validator.pause();  feedbackAux = {}; dialogFeedback = false;")
                v-icon close
              span Fechar
          v-card-text
            form(@submit.prevent="salvar")
              v-layout(row, wrap)
                v-flex.md6.xs12.pa-1
                  v-text-field(label="Texto", color="gray", required, v-validate="'required'", :error-messages="errors.collect('texto')", data-vv-name="texto", data-vv-as="texto", type="text", v-model='feedbackAux.texto')
                v-flex.md6.xs12.pa-1
                  v-autocomplete(label="Selecione uma categoria", :items="listCategoriasFeedbacks", :disabled="!listCategoriasFeedbacks.length", item-text="texto", item-value="id", color="gray", v-model="feedbackAux.categoria_feedback_id", :loading="loadingCategoriasFeedbacks", :clearable="true", autocomplete, :filter="customFilter", required, v-validate="'required'")
              v-layout(row, wrap)
                v-flex.xs12.text-xs-right
                  v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar

</template>

<script>
import _ from 'lodash';
import AccessLock from '@/components/AccessLock';

export default {
  components: {
    AccessLock
  },
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
    filter: { ativo: 1 },
    feedbacks: [],
    listCategoriasFeedbacks: [],
    loadingCategoriasFeedbacks: false,
    headers: [
      { text: '#', align: 'center', value: 'id' },
      { text: 'Texto', align: 'left', value: 'texto' },
      { text: 'Categoria', align: 'left', value: 'categoria_feedback.texto' },
      { text: 'Ações', align: 'center', sortable: false },
    ],
    search: '',
    loading: false,
    dialogFeedback: false,
    feedbackAux: {},
  }),
  computed: {
    list () {
      return _.filter(this.feedbacks, { ativo: this.filter.ativo });
    }
  },
  mounted () {
    this.getFeedbacks();
  },
  methods: {
    async getFeedbacks () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/feedback', { params : { fields : "categoria_feedback" } });
        vm.feedbacks = response.data.rows;
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async getCategoriasFeedbacks () {
      let vm = this;
      try {
        const response = await vm.$axios.get(`/categoria-feedback`);
        vm.listCategoriasFeedbacks = response.data.rows || [];
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async changeAtivo(feedback) {
      const vm = this;
      try {
        const obj = _.cloneDeep(feedback);
        obj.ativo = obj.ativo ? 0 : 1;
        const response = await vm.$axios.put(`/feedback/${obj.id}`, obj);
        feedback.ativo = response.data.ativo;
        window.getApp.$emit('APP_ALERT', { color: obj.ativo ? 'success' : 'orange', text: `Feedback ${feedback.ativo ? 'ativado' : 'desativado'} com sucesso!` });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar o feedback. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
      }
    },
    async save (feedback) {
      const vm = this;
      try {
        const response = await vm.$axios.post('/feedback', feedback);
        feedback.id = response.data.id;
        response.data.categoria_feedback = _.find(vm.listCategoriasFeedbacks , { id : feedback.categoria_feedback_id });
        vm.feedbacks.push(response.data);
        vm.loading = false;
        vm.dialogFeedback = false;
        vm.feedbackAux = {};
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Feedback salvo com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar o feedback. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async update (feedback) {
      const vm = this;
      try {
        delete feedback.CategoriaFeedbackId;
        const response = await vm.$axios.put(`/feedback/${feedback.id}`, feedback);
        const index = _.findIndex(vm.feedbacks, { id: feedback.id });
        const feedbacks = _.reject(vm.feedbacks, { id: feedback.id });
        response.data.categoria_feedback = _.find(vm.listCategoriasFeedbacks , { id : feedback.id });
        vm.feedbacks = [...feedbacks.slice(0, index), response.data, ...feedbacks.slice(index)];
        vm.loading = false;
        vm.dialogFeedback = false;
        vm.feedbackAux = {};
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Feedback salvo com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao atualizar o feedback. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async salvar (scope) {
      const vm = this;
      try {
        vm.loading = true;  
        const result = await vm.$validator.validateAll();
        if (!result) throw 'Preencha todos os campos corretamente!';
        vm[vm.feedbackAux.id ? 'update' : 'save'](vm.feedbackAux);
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    },
    async openDialogFeedback(feedback) {
      const vm = this;
      try {
        if (feedback && feedback.id) {
          vm.feedbackAux = _.cloneDeep(feedback);
          const response = await vm.$axios.get(`/feedback/${vm.feedbackAux.id}`);
          vm.feedbackAux = response.data || {};
        }
        vm.getCategoriasFeedbacks();
        vm.dialogFeedback = true;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
      }
    },
    customFilter (item, queryText, itemText) {
      const hasValue = val => val != null ? val : '';
      const text = hasValue(item.nome || item.texto);
      const query = hasValue(queryText);
      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
    }
  }
};
</script>

<style scoped>

</style>

